.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fabButton1 {
  position: 'absolute';
  top: 7;
  right: 200;
  margin: '0 auto';
  background: #ffa000;
}

.fabButton1:hover {
  background: #ffc107;
}

.botonBadak{
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

/*CLASES DE PABLO*/

.oso {
  margin: auto;
  padding: 10px;
}

.targeta{
  margin: auto;
}

.pregunta{
  background-color:#40c4ff;
}
.pregunta2{
  background-color:#40c4ff;
}
.cabecera{
  background-color:#ee7203;
}

.fin{
  align-items: center;
}

.hidelemts{
  display: none;
}

.showelemts{
  display: inline;
}

.paneles{
  background-color:#ee7203;
}

.panelesecond{
  background-color:#eceff1;
}

.Textos{
  color: #4CAF50;
}
